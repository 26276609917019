<template>
  <div>
    <van-nav-bar
      :title="title"
      :left-text="leftText"
      :right-text="rightText"
      :left-arrow="leftArrow"
      @click-left="onClickLeft"
      @click-right="onClickRight"
    >
      <template v-if="isRight" #right>
        <van-button size="mini" round>{{ rightText }}</van-button>
      </template>
    </van-nav-bar>
  </div>
</template>
<script>
import { NavBar, Toast, Button } from 'vant';
import { stringify } from 'qs';
export default {
  props: {
    title: {
      type: String,
      require: false,
      default: ''
    },
    leftText: {
      type: String,
      require: false,
      default: ''
    },
    rightText: {
      type: String,
      require: false,
      default: ''
    },
    leftArrow: {
      type: Boolean,
      require: false,
      default: false
    },
    isRight: {
      type: Boolean,
      require: false,
      default: false
    }
  },
  components: {
    vanNavBar: NavBar,
    vanButton: Button
  },
  methods: {
    onClickLeft() {
      this.$emit('click-left');
    },
    onClickRight() {
      this.$emit('click-right');
    }
  }
};
</script>
