<template>
  <div class="posted" :style="{ paddingTop: statusBarHeight + 'px' }">
    <NavBar
      class="nav-bar-style"
      title="发帖"
      rightText="发表"
      leftText="取消"
      :isRight="true"
      @click-left="onClickLeft"
      @click-right="onClickRight"
    />
    <div class="posted-content">
      <div class="posted-textarea">
        <textarea
          id="content"
          placeholder="分享新鲜事…"
          maxlength="500"
          contenteditable="true"
          v-model="params.content"
        ></textarea>
        <!-- <span class="text-count">{{ postedValue.length > 200 ? 200 : postedValue.length }}/200</span> -->
      </div>
      <div class="upload-img">
        <div>
          <img-upload
            v-for="(item, index) in imgUrlList"
            :key="item.base64Len + index"
            :item="item"
            :index="index"
            :imgUrlList="imgUrlList"
          />
          <div class="upload-group" v-show="imgUrlList.length < 3">
            <img src="@/assets/images/uploadImg/add.png" alt="" />
            <form class="upload-btn" action @change="uploadImg">
              <input ref="uploadInput" type="file" name="pic" accept="image/*" multiple="multiple" />
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import imgUpload from '@/components/ImgUpload';
import NavBar from '@/components/NavBar';

import Http from '@/utils/Http';
import { INIT_IMGURL, IMG_GROUP } from '@/store/common/common';
import { Toast } from 'vant';
import { CHECK_REAL_AUTH, GO_REAL_AUTH, CLOSE_WEBVIEW } from '@/utils/Agreement';

import lrz from 'lrz'; //外部插件

import { createNamespacedHelpers } from 'vuex';
const { mapState: commonState, mapMutations: commonMutations, mapActions: commonActions } = createNamespacedHelpers(
  'common'
);

const POSTED_URL = 'cloud/ec/tally/addCommunity';

export default {
  components: {
    imgUpload,
    NavBar
  },
  data() {
    return {
      isRealAuth: null,
      params: {
        content: '',
        imgUrl: ''
      },
      statusBarHeight: localStorage.getItem('statusBarHeight') ? localStorage.getItem('statusBarHeight') : 0
    };
  },
  computed: {
    ...commonState(['imgUrlList'])
  },
  methods: {
    ...commonMutations({
      initImgUrl: INIT_IMGURL,
      imgGroup: IMG_GROUP
    }),
    onClickLeft() {
      // this.$router.go(-1);
      window.location.href = CLOSE_WEBVIEW;
    },
    // 发送
    onClickRight() {
      if (!this.params.content) {
        Toast('发布内容不能为空！');
        return;
      }
      console.log(this.isRealAuth);
      if (!this.isRealAuth) {
        window.location.href = GO_REAL_AUTH;
        return;
      }
      this.params.imgUrl = this.imgUrlList
        .map((d) => {
          return d.reallyUrl;
        })
        .toString();
      Http.post(POSTED_URL, this.params)
        .then((result) => {
          this.params.content = '';
          this.params.imgUrl = '';
          this.initImgUrl();
          this.onClickLeft();
          // this.$router.go(-1);
          Toast('发布成功！');
        })
        .catch((error) => {
          handlerEror(error);
        });
    },
    uploadImg() {
      let file = this.$refs.uploadInput.files; //获取多张图片
      let files = [...file]; //将多张图片转化成数组格式
      files.forEach((e, index) => {
        //遍历file数组，实现一张一张上传
        lrz(e, { width: 640, quality: 1 }) //图片解压
          .then((rst) => {
            console.log(rst);
            if (this.imgUrlList.length >= 3) {
              //图片大于三张就停止上传后面的图片，并给出提示
              showToast('最多只能上传3张');
              return;
            }
            rst.reallyUrl = [];
            this.imgGroup(rst); //实现图片预览效果
            //重置上传input，解决删除后再次上传同一文件和两次上传同一文件问题
            this.$refs.uploadInput.value = '';
          })
          .catch((error) => {
            handlerEror(error);
          });
      });
    },
    refresh() {
      if (this.isRealAuth) {
        this.onClickRight();
        return;
      }
    }
  },
  created() {
    this.initImgUrl();
    window.refresh = this.refresh();
    window.location.href = CHECK_REAL_AUTH;
  },
  mounted() {
    window.getRealAuth = (result) => {
      console.log('返回了认证的结果');
      console.log(result);
      if (result == 'true') {
        console.log('走了true');
        console.log(result);
        this.isRealAuth = true;
        return;
      }
      console.log('走了false');
      this.isRealAuth = false;
    };
  }
};
</script>

<style scoped>
.upload-group {
  width: 24.533vw;
  height: 24.533vw;
  background-color: #f1f2f5;
  color: #ffffff;
  text-align: center;
  position: relative;
  float: left;
  border-radius: 3px;
  border: 0.27vw solid #f1f2f5;
  margin-bottom: 1.6vw;
}
.upload-group > img {
  width: 11.2vw;
  margin-top: 7.06vw;
}
.upload-btn {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
  border-radius: 2vw;
}
.upload-btn input {
  width: 24.533vw;
  height: 24.533vw;
  -webkit-user-select: auto;
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
}

.posted-content {
  padding: 5.33vw;
}
.posted-textarea {
  background-color: #ffffff;
  min-height: 41.6vw;
}

.posted-textarea textarea {
  font-size: 4.267vw;
  width: 100%;
  min-height: 28vw;
  /* color: #70798d; */
  -webkit-user-select: text !important;
}
.posted-textarea span {
  position: absolute;
  right: 3.13vw;
  bottom: 2.66vw;
  font-size: 2.67vw;
  color: #70798d;
}
.upload-img {
  /* min-height: 55.07vw; */
  background-color: #ffffff;
  margin-top: 2.13vw;
  padding-bottom: 3.2vw;
  display: flex;
  flex-direction: column;
}

.nav-bar-style >>> .van-button {
  width: 15.467vw;
  height: 6.4vw;
  background: rgba(81, 204, 180, 1);
  color: #ffffff;
  border-radius: 6.4vw;
}
.nav-bar-style >>> .van-nav-bar__text {
  color: #1d1d1d;
  font-size: 4.267vw;
}
</style>
