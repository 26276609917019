<template>
  <div class="img-group">
    <div class="close-btn" @click="delImgItem(index)" v-show="item.reallyUrl.length > 0">
      <span></span>
    </div>
    <img v-show="item.reallyUrl.length > 0" :src="item.reallyUrl" @click="showBigImg" />
    <div class="loading-icon" v-show="item.reallyUrl.length <= 0">
      <van-loading type="spinner" />
    </div>
    <div class="big-img" v-show="bigImgShow" @click.prevent="bigImgShow = false">
      <img :src="item.reallyUrl" />
    </div>
  </div>
</template>
<script>
import { Loading, Toast } from 'vant';
import axios from 'axios';

const UPLOAD = 'cloud/user/account/fileUpload';
export default {
  props: ['item', 'index', 'imgUrlList'],
  data() {
    return {
      bigImgShow: false,
      iosTop: 0
    };
  },
  components: {
    vanLoading: Loading
  },
  methods: {
    // 删除图片
    delImgItem() {
      this.imgUrlList.splice(this.index, 1);
    },
    // 发送上传图片请求
    fileUpload(params) {
      axios({
        method: 'post',
        url: UPLOAD,
        data: params
      })
        .then((result) => {
          this.item.reallyUrl = result.data;
        })
        .catch((error) => {
          console.log(error.desc);
          Toast(error.desc);
        });
    },
    // 上传参数处理
    upLoadImg() {
      let formData = new FormData();
      formData.append('timestamp', new Date().getTime());
      formData.append('sessionId', localStorage.getItem('token'));
      formData.append('imageFile', this.item.file, new Date().getTime());
      this.fileUpload(formData);
    },
    /**
     * 放大图片
     */
    showBigImg(item) {
      this.bigImgShow = true;
    }
  },
  mounted() {
    this.upLoadImg();
  }
};
</script>
<style scoped>
.img-group {
  width: 25.33vw;
  height: 25.33vw;
  float: left;
  margin-right: 2.66vw;
  margin-bottom: 1.6vw;
  position: relative;
  border: 0;
  border-radius: 3px;
  overflow: hidden;
}
.img-group > img {
  width: 100%;
  height: 100%;
}
.img-group .close-btn {
  width: 18px;
  height: 18px;
  background: url('../assets/images/uploadImg/delete.png') center center no-repeat;
  background-size: contain;
  text-align: center;
  color: #ffffff;
  border-radius: 50%;
  position: absolute;
  right: 2px;
  top: 3px;
}
.loading-icon {
  position: absolute;
  top: 2vw;
  left: 0;
  width: 100%;
  height: 100%;
  text-align: center;
  line-height: 20vw;
}
.big-img {
  position: fixed;
  top: 0;
  left: 0;
  left: 0;
  sform: translate(-50%, -50%);
  z-index: 10;
  height: 100%;
  width: 100%;
  background: #0000004d;
  display: flex;
  justify-content: center;
  align-items: center;
}
.big-img img {
  width: 100%;
}
</style>
